
import { IonPage, IonContent, IonInput } from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import MainHeader from "../components/MainHeader.vue";
import { useStore } from "@/store";
import MainFooter from "../components/MainFooter.vue";
import { useGlobalFunctions } from "@/libs/globalFunctions";
import { useLocalStorage } from "@/libs/localStorage";
import httpApp from "@/libs/http";
import settingsApp from "@/libs//settings";

import cartApp from "@/libs/cart";

export default defineComponent({
	name: "Cart",
	setup() {
		const { t, te, tm } = useI18n();
		const { formatNumberLocale } = useGlobalFunctions();
		const { getLocalStorageData } = useLocalStorage();
		const router = useRouter();
		const storeX = useStore();
		const productImgUrl = getLocalStorageData("websiteUrl");

		const showMinimumMsg = ref(false);
		const quote = ref(null);
		const cartItems = ref([]);
		const cartItemsQtyData = ref([]);
		const couponCode = ref("");

		const gettingQuote = ref(true);

		const totals = ref({
			subtotal: 0,
			deliveryFees: 0,
			discount: 0,
			wallet: 0,
			offer: 0,
			loyalty: 0,
			grandTotal: 0,
		});

		const defaultAddressStateId = ref(null);
		const setDefaultAddressStateId = async () => {
			if (storeX.state.shippingStateId) {
				defaultAddressStateId.value = storeX.state.shippingStateId;
			} else {
				const resp = (await httpApp.sendGetRequest(
					settingsApp.getEndpointUrl("getDefaultAddresses")
				)) as any;
				if (resp.success) {
					defaultAddressStateId.value = resp.data["billing"]["region_id"];
				} else {
					defaultAddressStateId.value = false;
				}
			}
		};

		const shippingTypes = ref({});
		const getShippingTypes = async (cartItem) => {
			if (!defaultAddressStateId.value) {
				await setDefaultAddressStateId();
			}
			if (!defaultAddressStateId.value) return;
			const sellerId = cartItem.product_data.seller_id;
			if (
				sellerId &&
				!Object.prototype.hasOwnProperty.call(shippingTypes.value, sellerId)
			) {
				try {
					const resp = (await httpApp.sendPostRequest(
						settingsApp.getEndpointUrl("getVendorById"),
						{ seller_id: sellerId }
					)) as any;
					if (resp.success) {
						httpApp
							.sendPostRequest(
								settingsApp.getEndpointUrl("getSellerShippingType"),
								{
									store_view: getLocalStorageData("storeCode"),
									seller_state: resp.vendor_data.state_id,
									buyer_state: defaultAddressStateId.value,
								}
							)
							.then((response: any) => {
								if (response.success) {
									shippingTypes.value[sellerId] = response.shipping_type;
								}
							})
							.catch((error) => {
								console.log(error);
							});
					}
				} catch (error) {
					console.log(error);
				}
			}
		};

		const getAllQuote = () => {
			cartApp.getQuote().then((result: any) => {
				showMinimumMsg.value = result.showMinimumMsg;
				totals.value.subtotal = result.subtotal;
				totals.value.deliveryFees = result.deliveryFees;
				totals.value.discount = result.discount;
				totals.value.wallet = result.wallet;
				totals.value.offer = result.offer;
				totals.value.loyalty = result.loyalty;
				totals.value.grandTotal = result.grandTotal;
			});
		};

		const getQuoteItems = async (itemQtyUpdate = false) => {
			if (!itemQtyUpdate) {
				gettingQuote.value = true;
			}
			const showLoading = itemQtyUpdate ? false : true;
			cartApp
				.getQuoteItems(showLoading, true)
				.then((result: any) => {
					const items = result;
					if (items.length > 0) {
						cartItems.value = result;
						const cartItemsQtyObj: any = {};
						let finishedProc = 0;
						for (let i = 0; i < items.length; i++) {
							const item = items[i];
							getShippingTypes(item);
							cartItemsQtyObj[item.item_id] = {
								qty: item.qty,
							};
							finishedProc++;
							if (finishedProc == items.length) {
								cartItemsQtyData.value = cartItemsQtyObj;
								gettingQuote.value = false;
							}
						}
					} else {
						cartItems.value = [];
					}
				})
				.catch(() => {
					cartItems.value = [];
					gettingQuote.value = false;
				});
		};

		const removeCartItem = async (cartItem: any) => {
			cartApp
				.removeFromCart(cartItem.item_id, cartItem.product_id, true)
				.then(() => {
					getQuoteItems();
					getAllQuote();
				})
				.catch(() => {
					gettingQuote.value = false;
				});
		};

		const cartItemQty = (event: any, carItem: any) => {
			cartItemsQtyData.value[carItem.item_id]["qty"] = event.target.value;
		};

		const updateItemsCart = async (itemQtyUpdate = false) => {
			cartApp
				.updateItemsCart(cartItemsQtyData.value, true)
				.then(() => {
					getQuoteItems(itemQtyUpdate);
					getAllQuote();
				})
				.catch((error) => {
					console.log(error, "updateCartQty");
					gettingQuote.value = false;
				});
		};

		const updateItemQtyInput = () => {
			setTimeout(() => {
				updateItemsCart(true);
			}, 500);
		};

		const minusQty = (carItem: any) => {
			if (cartItemsQtyData.value[carItem.item_id]["qty"] > 1) {
				cartItemsQtyData.value[carItem.item_id]["qty"] -= 1;
				updateItemsCart(true);
			}
		};

		const addQty = (carItem: any) => {
			cartItemsQtyData.value[carItem.item_id]["qty"] += 1;
			updateItemsCart(true);
		};

		const applayPromo = async (event: any) => {
			event.preventDefault();
			cartApp
				.applyPromoCode(couponCode.value, true)
				.then(() => {
					getQuoteItems();
					getAllQuote();
				})
				.catch((error) => {
					console.log(error, "updateCartQty");
					gettingQuote.value = false;
				});
		};

		const getImageUrl = (product: any) => {
			if (product.image && product.image != "no_selection") {
				return productImgUrl + "media/catalog/product" + product.image;
			} else if (product.thumbnail && product.thumbnail != "no_selection") {
				return productImgUrl + "media/catalog/product" + product.thumbnail;
			} else {
				return "assets/images/placeholder.png";
			}
		};

		const itemPriceTxt = (rowTotal: any) => {
			const formatRowTotal =
				formatNumberLocale(rowTotal) + " " + storeX.state.currency;
			return formatRowTotal;
		};
		const goToCheckOut = () => {
			router.push("/checkout");
		};
		const goToHome = () => {
			router.push("/");
		};

		const getShippingText = (shippingType) => {
			let text = t("product.deliveryWithIn");
			if (shippingType["delivery_duration_type"] == 1) {
				text +=
					" " +
					t("product.hoursCount", Number(shippingType["delivery_duration"]));
			} else {
				text +=
					" " +
					t("product.daysCount", Number(shippingType["delivery_duration"]));
			}
			text += " (" + shippingType["name"] + ")";
			return text;
		};

		function ionViewWillEnter() {
			gettingQuote.value = true;
			getAllQuote();
			getQuoteItems();
		}

		return {
			t,
			te,
			tm,
			storeX,
			quote,
			showMinimumMsg,
			totals,
			ionViewWillEnter,
			removeCartItem,
			updateItemsCart,
			updateItemQtyInput,
			cartItemQty,
			minusQty,
			addQty,
			cartItemsQtyData,
			itemPriceTxt,
			getImageUrl,
			cartItems,
			couponCode,
			applayPromo,
			goToCheckOut,
			goToHome,
			gettingQuote,
			formatNumberLocale,
			shippingTypes,
			getShippingText,
		};
	},
	components: {
		IonPage,
		IonContent,
		IonInput,
		MainHeader,
		MainFooter,
	},
});
